import React from "react";
import SuccessView from "../views/SuccessView";
import PubSub from "../pubsub";
import axios from 'axios';
import helperFunctions from "../tools/helperFunctions";
import "../css/success_view.scss"
const baseURL = process.env.REACT_APP_BASE_URL;
const urlParams = new URLSearchParams(window.location.search);
const quoteID = urlParams.get('quote_id');


class SuccessController extends React.Component{
    constructor(props){
        super(props)
        this.state={
           quote: null,
           quoteStatus: 0
        }
    }
    
    render(){
        const quoteHeader= ["Your quote was not found!", 
                            "Your quote was successfully submitted!",
                            "Your invoice is ready!",]
        const quoteBody = ["Contact us or check your spam folder to find an existing quote or start a new quote.",
                            //"Your quote is ready to be downloaded and a representative will follow up with you shortly! A copy of this quote has also been sent to your email. Be sure to check your spam folder if you cannot find it.",
                            "Your quotw has been submitted representative will follow up with you shortly!",
                            "Your quote has been converted to an invoice and is ready to be paid!"]

        return(
       <SuccessView >
          <success-header>{quoteHeader[this.state.quoteStatus]}</success-header>
          <success-body>{quoteBody[this.state.quoteStatus]}</success-body>
          <success-ctas>
            {(quoteID !== null)?
            
            <quote-cta className="download-quote" onClick={()=>{
                window.location.href ="https://pssk12.com";
            }}>Return Home</quote-cta>
            :
            <quote-cta className="download-quote" onClick={()=>{
                 window.location.pathname ="/";
            }}>Contact Us</quote-cta>
          }
          </success-ctas>

          <contact-us-link onClick={()=>{
               window.location.href = "mailto:info@pssk12.com";
          }}></contact-us-link>
        </SuccessView>

        )
    }

    componentDidMount(){
        /*
        PubSub.publish.is_loading(true)
        const url = "quote/" + quoteID;
        axios({
            headers:{
            'Content-Type': 'application/json',
            'Authorization': "none",
            },
            method: "POST",
            url: baseURL + url
        })
        .then(response => {
            if(response.status  === 200){
               this.setState(()=>({
                quote: response.data.quote,
                quoteStatus: response.data.quote.invoice === null? 1:2 
               }))
            }
        })
        .catch(error => {
            helperFunctions.handleError(error)
        })
        .then(()=>{
            PubSub.publish.is_loading(false)
        });
        */
    }

    downloadQuotePDF(){
        PubSub.publish.is_loading(true)
        const url = "quote/" + quoteID + "/download";
        axios({
            headers:{
            'Content-Type': 'application/json',
            'Authorization': "none",
            },
            method: "POST",
            url: baseURL + url
        })
        .then(response => {

            if(response.status  === 200){
                this.downloadResourceAsPDF(response.data.encoded_pdf)
                PubSub.publish.new_toast_message({
                    type: 'success',
                    header: "Your quote was downloaded!",
                    message: "Check your downloads folder to view the PDF document."
                })
            }
        })
        .catch(error => {
            helperFunctions.handleError(error)
        })
        .then(()=>{
            PubSub.publish.is_loading(false)
        });
    }

    downloadResourceAsPDF(encoded_pdf){
          const linkSource = "data:application/octet-stream;base64," + encoded_pdf;
          const link = document.createElement('a');
          link.href = linkSource;
          link.target = "_blank";
          link.rel = "noreferrer";
          link.download = "PSS Poster Maker System Quote.pdf";
          link.click()
     }

     getInvoiceLink(){
          PubSub.publish.is_loading(true)
          const url = "invoice/" + quoteID + "/link";
        axios({
            headers:{
            'Content-Type': 'application/json',
            'Authorization': "none",
            },
            method: "POST",
            url: baseURL + url
        })
        .then(response => {

            if(response.status  === 200){
                window.open(response.data.invoice_response.hosted_invoice_url, "_blank")
                this.setState(()=>({

                    quoteStatus: 2 
                }))
            }
            else if(response.status === 400){
                  PubSub.publish.new_toast_message({
                    type: 'error',
                    header: "",
                    message: response.data.invoice_status
                })
            }
        })
        .catch(error => {
            helperFunctions.handleError(error)
        })
        .then(()=>{
            PubSub.publish.is_loading(false)
        });
    }
}



export default SuccessController